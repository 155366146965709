//Positons
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

.sticky {
    position: sticky;
}

// Float
.float-right {
    float: right;
}

// Display
.d-none {
    display: none;
}

.d-block {
    display: block;
}

.d-inline {
    display: inline;
}

.d-inline-block {
    display: inline-block;
}

.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.d-grid {
    display: grid;
}

.align-middle {
    vertical-align: middle;
}

.place-content-center {
    place-content: center;
}

// Height
.h-screen {
    height: 100vh;
}
.w-screen {
    width: 100vw;
}

// Flex shrink
.flex-shrink {
    flex-shrink: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

// Alignment
.items-start {
    -webkit-box-align: start;
    align-items: flex-start;
}

.items-end {
    -webkit-box-align: end;
    align-items: flex-end;
}

.items-center {
    -webkit-box-align: center;
    align-items: center;
}

.justify-start {
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.justify-end {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.justify-center {
    -webkit-box-pack: center;
    justify-content: center;
}

.justify-between {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.self-center {
    align-self: center;
}

.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: end;
}

// Flex Direction
.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.flex-col-reverse {
    flex-direction: column-reverse;
}

// Flex Wrap
.flex-wrap {
    flex-wrap: wrap;
}

.flex-no-wrap {
    flex-wrap: nowrap;
}

//Flex
.flex-1 {
    flex: 1 1 0%;
}

.flex-auto {
    flex: 1 1 auto;
}

.flex-grow {
    flex-grow: 1;
}

//Flex Order
.order-first {
    order: -9999;
}

.order-last {
    order: 9999;
}

.order-none {
    order: 0;
}

//Grid
.col-gap-20 {
    column-gap: 20px;
}

.col-gap-12 {
    column-gap: 12px;
}

.row-gap-40 {
    row-gap: 40px;
}

.row-gap-32 {
    row-gap: 32px;
}

.row-gap-24 {
    row-gap: 24px;
}

.row-gap-20 {
    row-gap: 20px;
}

.row-gap-16 {
    row-gap: 16px;
}

.row-gap-4 {
    row-gap: 4px;
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

//z-index
.z-10 {
    z-index: 10;
}

.z-100 {
    z-index: 100;
}

// Top/Right/Bottom/Left
.\-top-1 {
    top: -1px;
}
.\-top-48 {
    top: -48px;
}
.\-top-96 {
    top: -96px;
}
.\-top-144 {
    top: -144px;
}
.\-top-192 {
    top: -192px;
}

.top-12 {
    top: 12px;
}

.top-0 {
    top: 0px;
}

.bottom-0 {
    top: 0px;
}

.left-8 {
    left: 8px;
}

.right-0 {
    right: 0px;
}

.inset-center-x {
    left: 50%;
    transform: translateX(-50%);
}

.inset-center-y {
    top: 50%;
    transform: translateY(-50%);
}

.inset-center {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

//Margins
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.-mx-40 {
    margin-left: -40px;
    margin-right: -40px;
}

.-mx-22 {
    margin-left: -22px;
    margin-right: -22px;
}

.-mx-18 {
    margin-left: -18px;
    margin-right: -18px;
}

.-mx-12 {
    margin-left: -12px;
    margin-right: -12px;
}

.-mx-10 {
    margin-left: -10px;
    margin-right: -10px;
}

.-mx-5 {
    margin-left: -5px;
    margin-right: -5px;
}

.mx-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.mx-22 {
    margin-left: 22px;
    margin-right: 22px;
}

.mx-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mx-12 {
    margin-left: 12px;
    margin-right: 12px;
}

.mx-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.mx-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.my-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.my-56 {
    margin-top: 56px;
    margin-bottom: 56px;
}

.my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
}

.my-22 {
    margin-top: 22px;
    margin-bottom: 22px;
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}
//margin-top
.mt-auto {
    margin-top: auto;
}

.mt-170 {
    margin-top: 170px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-98 {
    margin-top: 98px;
}

.mt-92 {
    margin-top: 92px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-64 {
    margin-top: 64px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-48 {
    margin-top: 48px;
}

.mt-46 {
    margin-top: 46px;
}

.mt-44 {
    margin-top: 44px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-38 {
    margin-top: 38px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-28 {
    margin-top: 28px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-18 {
    margin-top: 18px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-14 {
    margin-top: 14px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-6 {
    margin-top: 6px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-4 {
    margin-top: 4px;
}

.mt-0 {
    margin-top: 0;
}

.\-mt-60 {
    margin-top: -60px;
}

.\-mt-20 {
    margin-top: -20px;
}

//margin-right
.mr-auto {
    margin-right: auto;
}

.mr-70 {
    margin-right: 70px;
}

.mr-40 {
    margin-right: 40px;
}

.mr-36 {
    margin-right: 36px;
}

.mr-32 {
    margin-right: 32px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-22 {
    margin-right: 22px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-16 {
    margin-right: 16px;
}

.mr-12 {
    margin-right: 12px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-8 {
    margin-right: 8px;
}

.mr-7 {
    margin-right: 7px;
}

.mr-4 {
    margin-right: 4px;
}

.mr-2 {
    margin-right: 2px;
}

//margin-bottom
.mb-150 {
    margin-bottom: 150px;
}

.mb-128 {
    margin-bottom: 128px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-85 {
    margin-bottom: 85px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-64 {
    margin-bottom: 64px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-58 {
    margin-bottom: 58px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-48 {
    margin-bottom: 48px;
}

.mb-46 {
    margin-bottom: 46px;
}

.mb-44 {
    margin-bottom: 44px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-38 {
    margin-bottom: 38px;
}

.mb-36 {
    margin-bottom: 36px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-28 {
    margin-bottom: 28px;
}

.mb-27 {
    margin-bottom: 27px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-22 {
    margin-bottom: 22px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-18 {
    margin-bottom: 18px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-14 {
    margin-bottom: 14px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-7 {
    margin-bottom: 7px;
}

.mb-6 {
    margin-bottom: 6px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-2 {
    margin-bottom: 2px;
}

.mb-0 {
    margin-bottom: 0;
}

.\-mb-60 {
    margin-bottom: -60px;
}

.\-mb-40 {
    margin-bottom: -40px;
}

.\-mb-24 {
    margin-bottom: -24px;
}

//margin-left

.ml-auto {
    margin-left: auto;
}

.ml-64 {
    margin-left: 64px;
}

.ml-48 {
    margin-left: 48px;
}

.ml-40 {
    margin-left: 40px;
}

.ml-36 {
    margin-left: 36px;
}

.ml-32 {
    margin-left: 32px;
}

.ml-28 {
    margin-left: 28px;
}

.ml-24 {
    margin-left: 24px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-16 {
    margin-left: 16px;
}

.ml-14 {
    margin-left: 14px;
}

.ml-12 {
    margin-left: 12px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-8 {
    margin-left: 8px;
}

.ml-4 {
    margin-left: 4px;
}

.ml-2 {
    margin-left: 2px;
}

.\-ml-8 {
    margin-left: -8px;
}

//Paddings
.p-40 {
    padding: 40px;
}

.p-38 {
    padding: 38px;
}

.p-10 {
    padding: 10px;
}

.p-16 {
    padding: 16px;
}

.p-18 {
    padding: 18px;
}

.px-80 {
    padding-left: 80px;
    padding-right: 80px;
}

.px-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.px-22 {
    padding-left: 22px;
    padding-right: 22px;
}

.px-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.px-16 {
    padding-left: 16px;
    padding-right: 16px;
}

.px-14 {
    padding-left: 14px;
    padding-right: 14px;
}

.px-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.px-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.px-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.px-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.py-14 {
    padding-top: 14px;
    padding-bottom: 14px;
}

.py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.py-6 {
    padding-top: 6px;
    padding-bottom: 6px;
}

.py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
}

//padding-top
.pt-42 {
    padding-top: 42px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-18 {
    padding-top: 18px;
}

.pt-14 {
    padding-top: 14px;
}

.pt-12 {
    padding-top: 12px;
}

.pt-8 {
    padding-top: 8px;
}

//padding-right
.pr-120 {
    padding-right: 120px;
}

.pr-60 {
    padding-right: 60px;
}

.pr-40 {
    padding-right: 40px;
}

.pr-36 {
    padding-right: 36px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-12 {
    padding-right: 12px;
}

.pr-10 {
    padding-right: 10px;
}

//padding-bottom
.pb-40 {
    padding-bottom: 40px;
}

.pb-28 {
    padding-bottom: 28px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-12 {
    padding-bottom: 12px;
}

.pb-7 {
    padding-bottom: 7px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-0 {
    padding-bottom: 0;
}

//padding-left
.pl-50 {
    padding-left: 50px;
}

.pl-40 {
    padding-left: 40px;
}

.pl-32 {
    padding-left: 32px;
}

.pl-22 {
    padding-left: 22px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-12 {
    padding-left: 12px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-8 {
    padding-left: 8px;
}

//Borders
.border-t-2 {
    border-top-width: 2px;
    border-style: solid;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
}

.border-2 {
    border-width: 2px;
    border-style: solid;
}

.border-t-1 {
    border-top-width: 1px;
    border-style: solid;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
}

.border-white {
    border-color: $white;
}

.border-grey {
    border-color: $light_grey;
}

.border-green {
    border-color: $green_1;
}

.border-red {
    border-color: $error_2;
}

.border-ultra-light-grey {
    border-color: $ultra_light_grey;
}

.border-blue-600 {
    border-color: $blue_600;
}

.border-orange-700 {
    border-color: $orange_700;
}

.underline {
    text-decoration: underline;
}

.strike-through {
    text-decoration: line-through;
}

.no-decoration {
    text-decoration: none !important;
}

.thin {
    text-decoration-thickness: 0.01em;
}

.underline-offset-small {
    text-underline-offset: 0.1em;
}

//Colors
.text-white {
    color: $white;
}

.text-orange-700 {
    color: $orange_700;
}

.text-orange-800 {
    color: $orange_800;
}

.text-green {
    color: $green_2;
}

.text-error {
    color: $error;
}

.text-light-grey {
    color: $light_grey;
}

.text-md-grey {
    color: $medium_grey;
}

.text-dark-grey {
    color: $dark_grey;
}

.text-ultra-dark-grey {
    color: $ultra_dark_grey;
}

.text-blue-600 {
    color: $blue_600;
}

.text-blue-700 {
    color: $blue_700;
}

.bg-none {
    background-color: transparent;
}

.bg-white {
    background-color: $white;
}

.bg-ultra-light-grey {
    background-color: $ultra_light_grey;
}

.bg-light-grey {
    background-color: $light_grey;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.normal-case {
    text-transform: none;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.text-12 {
    font-size: 12px;
}

.text-14 {
    font-size: 14px;
}

.text-16 {
    font-size: 16px;
}

.text-20 {
    font-size: 20px;
}

.text-industry {
    font-family: "Industry", "Open Sans", "Proxima Nova", -apple-system, Arial, sans-serif;
}

.leading-40 {
    line-height: 40px;
}

.leading-32 {
    line-height: 32px;
}

.leading-20 {
    line-height: 20px;
}

.leading-24 {
    line-height: 24px;
}

.rounded-circle {
    border-radius: 50%;
}

.rounded-16 {
    border-radius: 16px;
}

.rounded-8 {
    border-radius: 8px;
}

.rounded-4 {
    border-radius: 4px;
}

.rounded-t-16 {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

// ANIMATIONS
.fade-in {
    animation: fadeIn ease-in-out 0.3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.font-extrabold {
    font-weight: 800;
}

.txt-medium {
    font-size: 18px;
    line-height: 24px;
}

.italic {
    font-style: italic;
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-none {
    pointer-events: none;
}

.break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.min-w-248 {
    min-width: 248px;
}

.min-w-183 {
    min-width: 183px;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.w-200 {
    width: 200px;
}

.list-reset {
    ul {
        list-style-type: disc;
        padding-left: 40px;
    }
}

.preview-height {
    height: 720px !important;
    flex: 0 0 720px !important;
}

//768 px screen
.sm_tablet {
    @include breakpoint(768px) {
        &\:justify-start {
            -webkit-box-pack: start;
            justify-content: flex-start;
        }
        &\:flex-col {
            flex-direction: column;
        }
        &\:flex-row {
            flex-direction: row;
        }

        //Flex Order
        &\:order-none {
            order: 0;
        }

        &\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        &\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        &\:grid-cols-4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &\:col-start-1 {
            grid-column-start: 1;
        }
        &\:col-end-3 {
            grid-column-end: 3;
        }

        &\:col-gap-20 {
            column-gap: 20px;
        }

        //Margins
        &\:mx-0 {
            margin-left: 0;
            margin-right: 0;
        }

        &\:mx-auto {
            margin-left: auto;
            margin-right: auto;
        }

        &\:-mx-40 {
            margin-left: -40px;
            margin-right: -40px;
        }

        &\:mx-40 {
            margin-left: 40px;
            margin-right: 40px;
        }

        &\:mx-auto {
            margin-left: auto;
            margin-right: auto;
        }
        &\:mt-120 {
            margin-top: 120px;
        }
        &\:mt-60 {
            margin-top: 60px;
        }

        &\:mt-52 {
            margin-top: 52px;
        }

        &\:mt-40 {
            margin-top: 40px;
        }

        &\:mt-32 {
            margin-top: 32px;
        }

        &\:mt-24 {
            margin-top: 24px;
        }
        &\:mt-20 {
            margin-top: 20px;
        }
        &\:mt-16 {
            margin-top: 16px;
        }
        &\:mt-8 {
            margin-top: 8px;
        }
        &\:mt-0 {
            margin-top: 0;
        }

        &\:mr-20 {
            margin-right: 20px;
        }

        &\:mr-16 {
            margin-right: 16px;
        }

        &\:mr-0 {
            margin-right: 0;
        }

        &\:mb-120 {
            margin-bottom: 120px;
        }
        &\:mb-64 {
            margin-bottom: 64px;
        }
        &\:mb-60 {
            margin-bottom: 60px;
        }
        &\:mb-50 {
            margin-bottom: 50px;
        }
        &\:mb-40 {
            margin-bottom: 40px;
        }
        &\:mb-38 {
            margin-bottom: 38px;
        }
        &\:mb-32 {
            margin-bottom: 32px;
        }
        &\:mb-24 {
            margin-bottom: 24px;
        }
        &\:mb-20 {
            margin-bottom: 20px;
        }
        &\:mb-22 {
            margin-bottom: 22px;
        }
        &\:mb-12 {
            margin-bottom: 12px;
        }
        &\:mb-10 {
            margin-bottom: 10px;
        }
        &\:mb-8 {
            margin-bottom: 8px;
        }
        &\:mb-4 {
            margin-bottom: 4px;
        }
        &\:mb-2 {
            margin-bottom: 2px;
        }
        &\:mb-0 {
            margin-bottom: 0;
        }

        &\:my-20 {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &\:ml-64 {
            margin-left: 64px;
        }
        &\:ml-auto {
            margin-left: auto;
        }
        &\:ml-32 {
            margin-left: 32px;
        }
        &\:ml-20 {
            margin-left: 20px;
        }
        &\:ml-16 {
            margin-left: 16px;
        }
        &\:ml-10 {
            margin-left: 10px;
        }
        &\:ml-8 {
            margin-left: 8px;
        }
        &\:ml-0 {
            margin-left: 0;
        }

        &\:p-40 {
            padding: 40px;
        }

        &\:py-20 {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        &\:py-18 {
            padding-top: 18px;
            padding-bottom: 18px;
        }

        &\:py-0 {
            padding-top: 0;
            padding-bottom: 0;
        }

        &\:pt-0 {
            padding-top: 0;
        }

        &\:pr-18 {
            padding-right: 18px;
        }

        &\:pr-12 {
            padding-right: 12px;
        }

        &\:pr-10 {
            padding-right: 10px;
        }

        &\:pb-0 {
            padding-bottom: 0;
        }

        &\:pl-40 {
            padding-left: 40px;
        }

        &\:pl-20 {
            padding-left: 20px;
        }

        &\:pl-12 {
            padding-left: 12px;
        }

        &\:pl-10 {
            padding-left: 10px;
        }

        &\:px-12 {
            padding-left: 12px;
            padding-right: 12px;
        }

        &\:px-10 {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }

        &\:d-none {
            display: none !important;
        }

        &\:d-flex {
            display: flex;
        }

        &\:d-inline-block {
            display: inline-block;
        }

        &\:d-block {
            display: block;
        }

        &\:leading-32 {
            line-height: 32px;
        }

        &\:flex-none {
            flex: 0 0 auto;
        }

        &\:border-2 {
            border-width: 2px;
            border-style: solid;
        }

        &\:max-w-55 {
            max-width: 55%;
        }
    }
}

//1024 px screen
.tablet {
    @include breakpoint(1024px) {
        &\:order-none {
            order: 0;
        }

        &\:flex-row {
            flex-direction: row;
        }

        &\:flex-col {
            flex-direction: column;
        }

        &\:grid-cols-1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &\:row-gap-32 {
            row-gap: 32px;
        }

        &\:row-gap-40 {
            row-gap: 40px;
        }

        &\:mx-0 {
            margin-left: 0;
            margin-right: 0;
        }

        &\:mx-4 {
            margin-left: 4px;
            margin-right: 4px;
        }
        &\:mt-90 {
            margin-top: 90px;
        }
        &\:mt-88 {
            margin-top: 88px;
        }
        &\:mt-60 {
            margin-top: 60px;
        }
        &\:mt-54 {
            margin-top: 54px;
        }
        &\:mt-40 {
            margin-top: 40px;
        }
        &\:mt-32 {
            margin-top: 32px;
        }
        &\:mt-20 {
            margin-top: 20px;
        }
        &\:mt-8 {
            margin-top: 8px;
        }
        &\:mt-5 {
            margin-top: 5px;
        }
        &\:mt-0 {
            margin-top: 0;
        }
        &\:mr-32 {
            margin-right: 32px;
        }
        &\:mb-64 {
            margin-bottom: 64px;
        }
        &\:mb-50 {
            margin-bottom: 50px;
        }
        &\:mb-48 {
            margin-bottom: 48px;
        }
        &\:mb-40 {
            margin-bottom: 40px;
        }
        &\:mb-32 {
            margin-bottom: 32px;
        }
        &\:mb-24 {
            margin-bottom: 24px;
        }
        &\:mb-20 {
            margin-bottom: 20px;
        }
        &\:mb-12 {
            margin-bottom: 12px;
        }
        &\:mb-5 {
            margin-bottom: 5px;
        }
        &\:mb-0 {
            margin-bottom: 0;
        }
        &\:ml-auto {
            margin-left: auto;
        }
        &\:ml-36 {
            margin-left: 36px;
        }
        &\:ml-16 {
            margin-left: 16px;
        }
        &\:ml-0 {
            margin-left: 0;
        }

        &\:p-40 {
            padding: 40px;
        }
        &\:pl-20 {
            padding-left: 20px;
        }

        &\:pr-60 {
            padding-right: 60px;
        }
        &\:pr-36 {
            padding-right: 36px;
        }
        &\:pr-0 {
            padding-right: 0;
        }

        &\:d-flex {
            display: flex;
        }
        &\:d-block {
            display: block;
        }
        &\:d-none {
            display: none !important;
        }
        &\:flex-1 {
            flex: 1 1 0%;
        }

        &\:text-20 {
            font-size: 20px;
        }
        &\:leading-20 {
            line-height: 20px;
        }
        &\:leading-24 {
            line-height: 24px;
        }
        &\:border-none {
            border-width: 0;
        }
    }
}

.sm_desktop {
    @include breakpoint(1280px) {
        &\:flex-row {
            flex-direction: row;
        }
        &\:flex-col {
            flex-direction: column;
        }

        &\:grid-cols-1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &\:justify-between {
            -webkit-box-pack: justify;
            justify-content: space-between;
        }
        &\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &\:leading-20 {
            line-height: 20px;
        }
        &\:text-left {
            text-align: left;
        }

        &\:text-19 {
            font-size: 19px;
        }        

        &\:mt-0 {
            margin-top: 0;
        }
        &\:mt-5 {
            margin-top: 5px;
        }
        &\:mt-8 {
            margin-top: 8px;
        }
        &\:mt-40 {
            margin-top: 40px;
        }
        &\:mb-104 {
            margin-bottom: 104px;
        }
        &\:mb-60 {
            margin-bottom: 60px;
        }
        &\:mb-40 {
            margin-bottom: 40px;
        }
        &\:mb-24 {
            margin-bottom: 24px;
        }
        &\:mb-20 {
            margin-bottom: 20px;
        }
        &\:mb-12 {
            margin-bottom: 12px;
        }
        &\:mb-8 {
            margin-bottom: 8px;
        }
        &\:mb-4 {
            margin-bottom: 4px;
        }
        &\:mb-0 {
            margin-bottom: 0;
        }
        &\:ml-auto {
            margin-left: auto;
        }
        &\:ml-0 {
            margin-left: 0;
        }
        &\:ml-12 {
            margin-left: 12px;
        }
        &\:ml-16 {
            margin-left: 16px;
        }
        &\:ml-20 {
            margin-left: 20px;
        }
        &\:ml-32 {
            margin-left: 32px;
        }
        &\:ml-36 {
            margin-left: 36px;
        }
        &\:mr-20 {
            margin-right: 20px;
        }
        &\:-mx-12 {
            margin-right: -12px;
            margin-left: -12px;
        }
        &\:mx-0 {
            margin-right: 0;
            margin-left: 0;
        }
        &\:my-20 {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &\:p-38 {
            padding: 38px;
        }
        &\:px-12 {
            padding-right: 12px;
            padding-left: 12px;
        }
        &\:pt-20 {
            padding-top: 20px;
        }
        &\:pt-18 {
            padding-top: 18px;
        }
        &\:pt-16 {
            padding-top: 16px;
        }
        &\:pt-0 {
            padding-top: 0;
        }
        &\:pr-60 {
            padding-right: 60px;
        }
        &\:pr-12 {
            padding-right: 12px;
        }
        &\:pl-12 {
            padding-left: 12px;
        }
        &\:py-0 {
            padding-top: 0;
            padding-bottom: 0;
        }
        &\:pl-20 {
            padding-left: 20px;
        }

        &\:d-block {
            display: block;
        }
        &\:d-flex {
            display: flex;
        }
        &\:d-none {
            display: none;
        }
        &\:flex-1 {
            flex: 1 1 0%;
        }
        &\:max-w-65 {
            max-width: 65%;
        }
    }
}

.hd_desktop {
    @include breakpoint(1440px) {
        &\:mt-0 {
            margin-top: 0;
        }
        &\:mb-40 {
            margin-bottom: 40px;
        }
    }
}

.lg_desktop {
    @include breakpoint(1900px) {
        &\:mt-0 {
            margin-top: 0;
        }
        &\:mb-40 {
            margin-bottom: 40px;
        }
    }
}
