//Colors
// TM Color palette
$ultra_light_grey: #eeefef;
$lighter_grey: #dedede;
$light_grey: #c1c5c8;
$medium_grey: #8a8c8e;
$dark_grey: #5b6670;
$ultra_dark_grey: #333f48;

.main-layout {
  --primary-color: #ff6633;
  --secondary-color: #ffeae3;
  --primary-color-lighter-1: hsl(from var(--primary-color) h s min(94, calc(l + 5)));
  --primary-color-lighter-2: hsl(from var(--primary-color) h s min(95, calc(l + 10)));
  --primary-color-lighter-3: hsl(from var(--primary-color) h s min(96, calc(l + 20)));
  --primary-color-lighter-4: hsl(from var(--primary-color) h s min(97, calc(l + 30)));
  --primary-color-lighter-5: hsl(from var(--primary-color) h s min(98, calc(l + 40)));
  --primary-color-lighter-6: hsl(from var(--primary-color) h s min(99, calc(l + 50)));
  --primary-color-darker-1: hsl(from var(--primary-color) h s max(0, calc(l - 10)));
}

$orange_800: var(--primary-color-darker-1);
$orange_700: var(--primary-color);
$orange_600: var(--primary-color-lighter-1);
$orange_500: var(--primary-color-lighter-2);
$orange_400: var(--primary-color-lighter-3);
$orange_300: var(--primary-color-lighter-4);
$orange_200: var(--primary-color-lighter-5);
$orange_100: var(--primary-color-lighter-6);

$blue_800: #003365;
$blue_700: #005486;
$blue_600: #2872a3;
$blue_500: #518ebc;
$blue_400: #79aad1;
$blue_300: #a2c5e3;
$blue_200: #cadef1;
$blue_100: #f2f7fc;

// Tag colors
$orange_tag_1: #ff6633;
$blue_tag_1: #00a0ce;
$blue_tag_2: #005486;
$red_tag_1: #ea1600;

// Status colors
$error: #ff3c3c;
$error_2: #ff6157;
$yellow_warning: #ffc755;
$green_1: #67c297;
$green_2: #009806;

// Other Colors
$blue_facebook: #4267b2;
$white: #fff;

//Font
$proxima: 'Proxima Nova';
$proxima_cond: 'Proxima Nova Cond';
